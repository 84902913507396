import './App.css';
import Page0 from './components/Page0';

function App() {
  return (
    <>
      <Page0/>
    </>
  );
}

export default App;
