import React from 'react';
import {  Layout, Avatar } from 'antd';
import myAvatar from './myAvatar.jpg';

const { Header, Content, Footer } = Layout;

const Page0 = () => {
    return(
        <Layout style={{background:"#ffe7ba", height:"100vh"}}>
            <Header style={{
                background:"#ffd591",
                color:"#000000",
                height:"80px",
                display: 'flex',
                //justifyContent: 'center'
            }}>
                <h1>流 霜</h1>
            </Header>
            <Layout>
                <Content style={{
                    background:"#fff7e6",
                    height:"80vh",
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                    }}>
                    <br/><br/><br/>
                    
                    <Avatar
                        size={{
                        xs: 120,
                        sm: 150,
                        md: 180,
                        lg: 256,
                        xl: 320,
                        xxl: 400,
                        }}
                        src={myAvatar}
                    />      
                    <div >
                    <h1>欢迎来到我的个人主页！</h1>
                    <h2>考试周后再开始完善页面……</h2>
                    <h2>祝大家期末好运 ヾ(^∀^)ﾉ </h2>
                    </div>
                </Content>               
            </Layout>
            <Footer style={{
                display: 'flex',
                justifyContent: 'center',
                background:"#ffe7ba",
                height:"8vh"
            }}>
            <a 
                href="https://beian.miit.gov.cn/" 
                target="_blank" 
                rel="noreferrer" 
                style={{color: 'gray'}}>
                滇ICP备2023016012号-2
            </a>
            </Footer>
        </Layout>
    );
}
export default Page0;